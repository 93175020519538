@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Lora:ital@0;1&family=Nunito:wght@200&family=Rajdhani&family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=EB+Garamond);
@import url(https://fonts.googleapis.com/css2?family=Reckless+Neue&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Rajdhani';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  font-family: 'Lora';
}

.bioPage {
  display: flex;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(255,102,203);
} 

.blogReadMore {
  position: absolute; 
  width: 100%; 
  bottom: -15%; 
  background-color: transparent;
}
.mediaPage {
  display: flex;
  margin: 4em;
}
.blogDateFormat {
  position: absolute;
  right: 15px;
  top: 10px;
}
.blogDateFormatShort {
  display: none;
}
.blogMapContainer {
  width: 80%; 
	/* border: 3px solid #0086fc !important; */
	border: 3px solid #194b77 !important;
	/* border: 3px solid #AD974F;  */
	box-shadow: 3px 3px 3px grey; 
	position: relative; 
	border-radius: 10px;
}
.blogIndexTitle {
  flex: 8 1;
  font-family: Roboto;
  padding: 6px;
}
.blogVideo {
  border-radius: 25px;
  width: 50%;
  height: 400px;
  box-shadow: 15px 15px 15px black;
  font-family: lora;
  }
.cndRdg {
  font-size: 20px;
}
.calendarYear {
  font-family: 'Reckless Neue';
  font-weight: 100;
  /* font-family: 'EB Garamond'; */
  /* color: blue; */
  font-size: 32px;
}
.discriptionWeb {
  text-align: center;
  margin: 7px;
  padding: 7px;
  line-height: 2.5em;
  flex: 6 1;
}
.eventPhoto {
  width: 80%;
  height: auto;
  border-radius: 23px;
  padding: 60px 10px 20px 20px;
  margin: auto;
}
.HoverClass1:hover {
  color: #194b77 !important;
}

.seeDetailsButton {
  background-color: #83e39d;
  color: black;
}
.seeDetailsButton:hover {
  background-color: black;
  color: white;
}
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stuff {
  font-size: 22px;
  padding: 8px;
}
.upcomingEvents {
  /* margin-left: 3%; */
  text-align: center!important;
  color: rgb(0, 79, 91);
  /* color: rgb(138, 114, 55); */
}
.videoWebStyle {
  width: 100%; 
  height: 400px; 
  box-shadow: 5px 5px 5px #9E9E9E;
}
/* Media queries for small screens */
@media only screen and (max-width: 600px) {
  .upcomingEvents {
    text-align: center!important;
    color: #194b77;
    /* color: rgb(138, 114, 55); */
  }
  .bioPage {
    display: block;
  }
  .eventPhoto {
    padding: 8px;
    border-radius: 12px;
    width: 100%;
    height: auto;
  }
  .mediaPage {
    /* display: block; */
    flex-direction: column;
    margin: 0px;
    padding: 6px;
    width: 100%;
  }
  .stuff {
    font-size: 18px;
    padding: 10px;
  }
  .discriptionWeb {
    margin-top: 15px;
  }
  .videoWebStyle {
    height: 275px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mediaReverse {
    flex-direction: column-reverse;
  }
  .formStyle {
    width: 95%;
  }
  .paragraphStyle {
    width: 100%;
  }
  .blogDate {
    display: none !important; 
  }
  .blogMapContainer {
    width: 95%; 
    border: 3px solid #AD974F; 
    box-shadow: 3px 3px 3px grey; 
    position: relative; 
    border-radius: 10px;
  }
  .blogDateMobile {
    display: block !important; 
  }
  .blogReadMore {
    bottom: -8%; 
  }
  .blogDateFormat {
    display: none;
  }
  .blogDateFormatShort {
    display: block !important;
    text-align: center;
  }
  .blogIndexTitle {
    text-align: center;
    padding-top: 8px !important;
  }
  .cndRdg {
    font-size: 14px;
  }
  .conRdg:hover {
    color: #EAEAEA;
    font-weight: bold;
  }
  
  .conRdgBorder:hover {
    border: 3px solid #9E9E9E !important;
    background-color: #8E793E!important;
  }

  .blogVideo {
  border-radius: 25px;
  width: 100%;
  height: 350px;
  box-shadow: 15px 15px 15px black;
  font-family: lora;
  }
  .blogVideo {
    border-radius: 25px;
    width: 100%;
    height: 260px;
    box-shadow: 15px 15px 15px black;
    font-family: lora;
    }
}

/* Media queries for large screens */
@media screen and (min-width: 3072px) {
  .stuff {
    font-size: 42px;
  }
  .upcomingEvents {
    text-align: center!important;
    /* color: rgb(138, 114, 55); */
    color: wheat;
  }
}
 



.alert{align-items:center;-webkit-animation:.5s ease-in-out 0s 1 light-bounce-in;animation:.5s ease-in-out 0s 1 light-bounce-in;bottom:1rem;display:flex;left:0;margin:auto;max-width:30rem;position:fixed;right:0;z-index:10000}.alert .alert-body{margin-bottom:0}.close:active,.close:focus{outline:none}@-webkit-keyframes light-bounce-in{0%{opacity:0;-webkit-transform:translateY(20%);transform:translateY(20%)}50%{-webkit-transform:translateY(-5%);transform:translateY(-5%)}100%{opacity:1;-webkit-transform:translateY(0%);transform:translateY(0%)}}@keyframes light-bounce-in{0%{opacity:0;-webkit-transform:translateY(20%);transform:translateY(20%)}50%{-webkit-transform:translateY(-5%);transform:translateY(-5%)}100%{opacity:1;-webkit-transform:translateY(0%);transform:translateY(0%)}}
